<template>
  <CodeReader
    v-if="showCodeCamera"
    ref="codeReader"
    @decode="onDecodeCodeData"
    @cancel="onCancelCodeCamera"
    class="animate__animated animate__fadeIn"
  />

  <div v-else>
    <Navbar>
      <template v-slot:navbarTitle>仕入管理</template>
    </Navbar>

    <main id="main" class="container-fluid pt-3">
      <div class="d-flex gap-2 mb-3">
        <h5 class="m-0 p-0">
          <i class="bi bi-file-earmark-text me-1"></i>不良品情報一覧
        </h5>
        <div class="ms-auto">
          <button
            type="button"
            v-on:click.prevent="confirmSubmit"
            class="btn btn-primary position-relative me-2"
          >
            <i class="bi bi-check-lg"></i>
            <span class="d-none d-sm-inline-block ms-1">確認済みにする</span>
            <span
              class="position-absolute top-0 start-100 translate-middle px-2 badge rounded-pill text-bg-danger"
            >
              {{ defectiveItemIds.length }}
            </span>
          </button>
          <button
            type="button"
            v-on:click.prevent="convertToCsvConfirm()"
            class="btn btn-dark"
          >
            <i class="bi bi-arrow-repeat"></i>
            <span class="d-none d-sm-inline-block ms-1"
              >データをCSVに変換する</span
            >
          </button>
        </div>
      </div>

      <div class="d-flex justify-content-start gap-2 mb-2">
        <div class="me-auto col-6 col-lg-4 col-xl-3">
          <search-form
            :query="query"
            query-key="number"
            placeholder="不良品番号で検索"
            :use-code-reader="true"
            :use-search-modal="true"
            @update-search-query="onUpdateSearchQuery"
            @start-code-camera="onStartCodeCamera"
            @open-search-modal="onOpenSearchModal"
          ></search-form>
        </div>
        <Pagination :prev="prev" :next="next" @move-page="movePage" />
      </div>

      <!-- defectiveItems -->
      <div v-if="defectiveItems && defectiveItems.length > 0">
        <data-table :headers="dataHeaders" :items="defectiveItems">
          <template #id="{ item }">
            <input
              @change="handleChecked(item.id, $event.target.checked)"
              type="checkbox"
              class="form-check-input"
              :id="`checkbox-${item.id}`"
              :checked="item.confirmed_at"
              :disabled="item.confirmed_at"
            />
          </template>
          <template #purchased_item_code="{ item }">
            <span class="text-muted d-block"
              >【{{ item.purchased_item_code }}】{{
                item.purchased_item_model
              }}</span
            >
            {{ item.purchased_item_name }}
          </template>
          <template #cause="{ item }">
            <span class="fw-semibold d-block">{{ item.cause }}</span>
            {{ item.cause_detail }}
          </template>
          <template #detected_at="{ item }">
            <span class="text-muted d-block">{{ item.detected_by }}</span>
            {{ item.detected_at }}
          </template>
          <template #confirmed_at="{ item }">
            <span class="text-muted d-block">{{ item.confirmed_by }}</span>
            {{ item.confirmed_at }}
          </template>
        </data-table>
      </div>
      <p v-else class="mt-3">該当するデータはありません。</p>
    </main>

    <!-- Modal -->
    <search-modal
      :query="query"
      :items="searchItems"
      @update-search-query="onUpdateSearchQuery"
      ref="searchModal"
    ></search-modal>
  </div>

  <Confirm ref="confirmSubmit" @confirm-true="formSubmit" />
  <Confirm ref="confirmConvertData" @confirm-true="convertToCsv" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import store from '../store'
import Confirm from '@/components/TheConfirm.vue'
import Navbar from '@/components/Navbar.vue'
import Pagination from '@/components/AppNoCountPagination.vue'
import DataTable from '@/components/common/DataTable.vue'
import CodeReader from '@/components/AppCodeReader.vue'
import SearchForm from '@/components/common/SearchForm.vue'
import SearchModal from '@/components/common/SearchModal.vue'

export default {
  name: 'ManagePurchaseDefectiveItemList',
  components: {
    Confirm,
    Navbar,
    Pagination,
    DataTable,
    CodeReader,
    SearchForm,
    SearchModal,
  },

  data() {
    return {
      query: {},
      showCodeCamera: false,
      defectiveItemIds: [],
      dataHeaders: [
        {
          key: 'id',
          label: '確認',
          class: 'col-1',
          format: 'id',
        },
        {
          key: 'number',
          label: '不良品番号',
          class: 'col-1',
          format: 'code',
        },
        {
          key: 'purchased_item_code',
          label: '品目',
          class: 'col-3',
          format: 'string',
        },
        {
          key: 'cause',
          label: '不良原因',
          class: 'col-3',
          format: 'string',
        },
        {
          key: 'detected_at',
          label: '検出日時',
          class: 'col-2',
          format: 'datetime',
        },
        {
          key: 'confirmed_at',
          label: '確認日時',
          class: 'col-2',
          format: 'datetime',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      classificationList: 'item_classification/list',
    }),
    defectiveItems: function () {
      return store.getters['manage_purchase_defective_item/results']
    },
    prev: function () {
      return store.getters['manage_purchase_defective_item/prev']
    },
    next: function () {
      return store.getters['manage_purchase_defective_item/next']
    },
    causeList() {
      return store.getters['manage_purchase_defective_item_cause/list']
    },
    searchItems() {
      return [
        {
          id: 'number',
          label: '不良品番号（完全一致）',
          type: 'text',
        },
        {
          id: 'classification',
          label: '品目分類',
          type: 'select',
          options: this.classificationList.map((item) => {
            return {
              value: item.id,
              name: item.name,
            }
          }),
        },
        {
          id: 'item_code',
          label: '品目コード（前方一致）',
          type: 'text',
        },
        {
          id: 'item_model',
          label: '型式（前方一致）',
          type: 'text',
        },
        {
          id: 'cause_id',
          label: '不良原因',
          type: 'select',
          options: this.causeList,
        },
        {
          id: 'detected_at',
          label: '不良検出日',
          type: 'date',
        },
        {
          id: 'is_confirmed',
          label: '確認済',
          type: 'checkbox',
        },
        {
          id: 'is_not_confirmed',
          label: '未確認',
          type: 'checkbox',
        },
      ]
    },
  },

  async created() {
    await this.getData()
    await this.getCauseList()
    await this.getClassificationList()
  },

  mounted() {
    window.scrollTo(0, 0)
  },

  methods: {
    ...mapActions({
      postExportData: 'export_data/postExportData',
      getClassificationList: 'item_classification/fetchList',
    }),
    getData(query = {}) {
      return store.dispatch('manage_purchase_defective_item/fetchAll', query)
    },
    getCauseList() {
      return store.dispatch('manage_purchase_defective_item_cause/fetchList', {
        limit: 0,
      })
    },
    movePage(page) {
      this.getData(Object.assign(this.query, { page: page }))
    },
    getQueryResult() {
      this.getData(this.query)
    },
    onOpenSearchModal() {
      this.$refs.searchModal.show()
    },
    onUpdateSearchQuery(query = {}) {
      this.query = query
      this.getQueryResult()
    },
    onStartCodeCamera() {
      this.showCodeCamera = true
    },
    onCancelCodeCamera() {
      this.showCodeCamera = false
    },
    onDecodeCodeData(result) {
      this.query.number = result
      this.showCodeCamera = false
    },
    handleChecked(itemId, checkedStatus) {
      if (checkedStatus) {
        this.defectiveItemIds.push(itemId)
      } else {
        this.defectiveItemIds = this.defectiveItemIds.filter(
          (id) => id !== itemId
        )
      }
    },
    confirmSubmit() {
      if (this.defectiveItemIds.length === 0) {
        this.$store.dispatch('alert/setErrorMessage', {
          message: 'データを選択してください。',
        })
        return
      }

      this.$refs.confirmSubmit.showConfirm(
        '',
        this.defectiveItemIds.length + '件のデータを確認済みにしますか？'
      )
    },
    async formSubmit() {
      try {
        await store.dispatch(
          'manage_purchase_defective_item/updateAll',
          this.defectiveItemIds
        )
        this.$store.dispatch('alert/setSuccessMessage', {
          message: '保存しました。',
        })
        this.getData()
        this.defectiveItemIds = []
      } catch (e) {
        console.log(e.response.data)
      }
    },
    convertToCsvConfirm() {
      this.$refs.confirmConvertData.showConfirm(
        'データを変換',
        'この内容でCSVに変換しますか？'
      )
    },
    convertToCsv() {
      const formData = {
        application: 'purchased-defective-items',
        conditions: Object.assign({}, this.query),
      }
      try {
        this.postExportData(formData)
        this.$store.dispatch('alert/setSuccessMessage', {
          message:
            'CSVファイルへの変換を開始しました。エクスポート管理からダウンロードしてください',
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
