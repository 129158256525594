import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  detail: {},
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  detail: (state) => state.detail,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearDetail')
      resolve()
    })
  },
  /**
   * データを追加する
   *
   * @param {object}
   * @return {object}
   */
  async postData({}, formData) {
    if ('item' in formData) {
      formData.item_id = formData.item.id
    }
    if ('cause' in formData) {
      formData.cause_id = formData.cause.id
    }

    // 必要なデータのみにする
    const requiredKeys = [
      'number',
      'item_id',
      'cause_id',
      'cause_detail',
      'quantity',
    ]
    const submitData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) =>
        requiredKeys.includes(key)
      )
    )

    try {
      await api.post(
        'operate/purchase/defective-items/bulk-create/',
        submitData
      )
    } catch (error) {
      throw error
    }
  },
  bulkCreate({ dispatch }, formData) {
    // formData.defectiveItems の数だけ postData を実行する
    let data = {}
    formData.defectiveItems.forEach((item) => {
      // 送信データを整形する
      data = {
        number: formData.number,
        item_id: formData.item.id,
        cause_id: item.cause.id,
        cause_detail: item.cause_detail,
        quantity: item.quantity,
      }
      dispatch('postData', data)
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
