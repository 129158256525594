<template>
  <CodeReader
    v-if="showCodeCamera"
    ref="codeReader"
    @decode="handleQRCodeData"
    @cancel="cancelCodeCamera"
  />

  <div v-else>
    <Navbar>
      <template v-slot:navbarButton>
        <div class="buttons">
          <router-link
            :to="{
              name: 'OperatePurchase',
            }"
            class="btn btn-dark"
            >戻る</router-link
          >
          <button
            type="button"
            class="btn btn-primary ms-2"
            v-on:click.prevent="formSubmit()"
          >
            登録
          </button>
        </div>
      </template>
    </Navbar>

    <main id="main" class="bg-light">
      <div class="container py-3 py-lg-4">
        <div class="row row-cols-1 gy-2 gy-lg-3">
          <div class="col">
            <div class="card">
              <div class="card-body">
                <div class="row gy-2">
                  <div class="col">
                    <label for="form_number" class="form-label"
                      >不良品番号</label
                    >
                    <div class="input-group">
                      <input
                        v-model="formData.number"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': formErrors?.number }"
                        id="form_number"
                        placeholder="不良品番号"
                      />
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        v-on:click.prevent="startCodeCamera"
                      >
                        <i class="bi bi-qr-code"></i>
                      </button>
                      <div v-if="formErrors?.number" class="invalid-feedback">
                        {{ formErrors.number }}
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <label for="form_item" class="form-label"
                      >品目<span class="badge text-bg-danger ms-2 px-1"
                        >必須</span
                      ></label
                    >
                    <FormSearchSelect
                      v-model="formData.item"
                      :options="itemList"
                      :error="formErrors.item_id"
                      id="form_item"
                      placeholder="品目コード、品目名で検索"
                      @getOptions="getItemList"
                    />
                  </div>
                  <div v-if="formData.item?.memo" class="col-12">
                    <label class="form-label">品目メモ</label>
                    <textarea
                      v-model="formData.item.memo"
                      class="form-control"
                      readonly
                    ></textarea>
                  </div>
                  <div class="col-12">
                    <label for="form_quantity" class="form-label">数量</label>
                    <input
                      v-model="formData.quantity"
                      type="text"
                      class="form-control bg-secondary"
                      :class="{ 'is-invalid': formErrors?.quantity }"
                      id="form_quantity"
                      readonly
                    />
                    <div v-if="formErrors?.quantity" class="invalid-feedback">
                      {{ formErrors.quantity }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix mt-3">
          <div class="float-end">
            <a href="#" class="link-primary" @click="showDefectItemForm()"
              ><i class="bi bi-plus-circle me-1"></i>詳細情報を追加する</a
            >
          </div>
          <h5>不良品詳細</h5>
        </div>
        <div v-if="formData.defectiveItems.length > 0">
          <div
            v-for="(item, index) in formData.defectiveItems"
            :key="index"
            class="card mb-1"
          >
            <div class="card-header">
              <div class="d-flex justify-content-between">
                {{ item.cause.cause }}
                <a href="#" class="link-danger" @click="deleteItem(index)">
                  <i class="bi bi-trash3"></i>
                </a>
              </div>
            </div>
            <div class="card-body">
              <div class="row g-3">
                <div class="col-10">
                  <input
                    type="text"
                    v-model="item.cause_detail"
                    class="form-control"
                  />
                </div>
                <div class="col-2 text-end">
                  <span class="form-control-plaintext">{{
                    item.quantity
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-danger">詳細情報がありません。</div>
      </div>

      <DefectiveItemForm
        ref="defectItemForm"
        @afterSubmit="insertItem"
        :client="formData.client"
      />
    </main>
  </div>
</template>

<script>
import store from '../store'
import { mapGetters, mapActions } from 'vuex'
import CodeReader from '@/components/AppCodeReader.vue'
import FormSearchSelect from '@/components/common/FormSearchSelect.vue'
import Navbar from '../components/PurchaseNavbar.vue'
import DefectiveItemForm from '../components/DefectiveItemForm.vue'

export default {
  name: 'OperatePurchaseDefectiveItemCreate',
  components: {
    CodeReader,
    Navbar,
    DefectiveItemForm,
    FormSearchSelect,
  },

  data() {
    return {
      showCodeCamera: false,
      formData: {
        number: '',
        item: null,
        quantity: 0,
        defectiveItems: [],
      },
      formErrors: {},
    }
  },
  computed: {
    ...mapGetters({
      itemList: 'operate_item/list',
    }),
  },

  methods: {
    ...mapActions({
      getItemList: 'operate_item/fetchList',
    }),
    startCodeCamera() {
      this.showCodeCamera = true
    },
    cancelCodeCamera() {
      this.showCodeCamera = false
    },
    handleQRCodeData(result) {
      this.formData.number = result
      this.showCodeCamera = false
    },
    formValidation() {
      this.formErrors = {}
      if (!this.formData.item) {
        this.formErrors.item_id = '品目を選択してください。'
      }
      if (!this.formData.quantity || this.formData.quantity.length === 0) {
        this.formErrors.quantity = '詳細情報の数量を確認してください。'
      }
      if (this.formData.defectiveItems.length === 0) {
        this.formErrors.defectiveItems =
          '詳細情報は必ず1つ以上追加してください。'
      }
    },
    async formSubmit() {
      this.formValidation()
      if (Object.keys(this.formErrors).length > 0) {
        return
      }

      try {
        await store.dispatch(
          'operate_purchase_defective_item/bulkCreate',
          this.formData
        )
        this.formData = {
          number: '',
          item: null,
          quantity: 0,
          defectiveItems: [],
        }
        this.$store.dispatch('alert/setSuccessMessage', {
          message: '保存しました',
        })
      } catch (e) {
        if (e.response?.status == 400) {
          Object.keys(e.response.data).forEach((key) => {
            this.setErrorMessage(key, e.response.data[key])
          })
        }
      }
    },
    setErrorMessage(key, errorData) {
      if (Array.isArray(errorData)) {
        this.formErrors[key] = errorData.join('\n')
      } else {
        this.formErrors[key] = errorData
      }
    },
    showDefectItemForm() {
      this.$refs.defectItemForm.show()
    },
    insertItem(item) {
      if (!this.formData.defectiveItems) {
        this.formData.defect_details = []
      }
      this.formData.defectiveItems.push(item)
      this.calcQuantity()
    },
    deleteItem(index) {
      this.formData.defectiveItems.splice(index, 1)
    },
    calcQuantity() {
      // defectiveItems の数量を合計する
      let quantity = 0
      this.formData.defectiveItems.forEach((item) => {
        quantity += parseFloat(item.quantity)
      })
      this.formData.quantity = quantity
    },
  },
}
</script>
